
:root {
    --base-size: 1.4em;
    --type-scale: 1.125;
    --small: calc(var(--base-size) / var(--type-scale));
    --h6:  calc(var(--type-scale) - 0.1);
    --h5:  calc(var(--base-size) * var(--type-scale));
    --h4:  calc(var(--h5) * var(--type-scale));
    --h3:  calc(var(--h4) * var(--type-scale));
    --h2:  calc(var(--h3) * var(--type-scale));
    --h1:  calc(var(--h2) * var(--type-scale));
    }
  
  *, *::before, *::after, html {
    box-sizing: border-box;
    font-size: 100%;
  }
  
  body {
    font-weight: 400;
    line-height: 1.65;
    margin: 0;
    background-color: hsl(0, 0%, 100%);
    padding: 0;
    overflow-x: hidden;
  }

  
body::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: hsl(231deg 68% 6% / 90%);
  }
  
  body::-webkit-scrollbar{
    width: 4px;
    background-color: hsl(231deg 68% 6% / 90%);
  }
  
  body::-webkit-scrollbar-thumb{
    background-color: hsl(231deg 68% 6% / 90%);
    border: 2px solid hsl(231deg 68% 6% / 90%);
  }

  body > footer{
    background-color: #101117;
}

  p {
      font-size: var(--base-size);
    }
    
  h1, h2, h3, h4, h5 {
      margin: 2.75rem 0 1.05rem;
      line-height: 1.15;
  }
    
  h1 {
    margin-top: 0;
    font-size: var(--h1);
  }
  
  h2 {font-size: var(--h2);}
  
  h3 {font-size: var(--h3);}
  
  h4 {font-size: var(--h4);}
  
  h5 {font-size: var(--h5);}
    
  .small, .text_small {
    font-size: var(--small); 
  }

header ~ div{
    margin-bottom: 0!important;
   }

   
@media only screen and (min-width: 1200px) {

    .container {
        max-width: 1550px!important;
    }
    .row-container-custom {
        padding-top: 3rem!important;
        margin-top: 3rem!important;
        background-color: hsl(226deg 78% 25% / 1%);
    }
    
}

@media only screen and (max-width: 1024px) {

    #root {
        overflow-y: auto;
        width: fit-content;
    }

    .footer-social-section{
        padding-top: .4rem!important;
    }

    .footer-social-section, .copyright-section{
        position: relative!important;
        bottom: 0!important;
        top: 0 !important;;
        left: 0!important;
        right: 0!important; 
        width: 100%!important;
        text-align: center;
        justify-content: center!important;
        display: flex;
        flex-flow: row wrap;
        place-content: center;
        place-items: center;
        margin: 0!important;
    }

    .copyright-section-text{
        font-size: 13px!important;
        margin-top: -5px!important;
    }

    .copyright-section{
        padding-top: 0!important;
    }

    .container, .container-fluid {
        max-width: 100%!important;
        padding: 0!important;
    }

    .row-container {
        margin-top: 2rem;
        width: 100%;
    }
    .row-container.row-container-custom {
        padding-top: 3rem!important;
        margin-top: 0!important;
        background-color: hsl(226deg 78% 25% / 1%);
    }

    .row-container:last-child {
        margin: 2rem 0!important;
    }
}


@media only screen and (width: 1024px) {
    .container-main-action {
        width: 80%!important;
    }
    .container-main-action > h6 {
        padding: .8rem 0;
    }
}


@media only screen and (width: 768px) {
    .container-main-action {
        width: 90%!important;
    }
    .container-main-action > h6 {
        padding: .8rem 0;
    }
}


@media only screen and (width: 640px) {
    .heroSubheader {
        font-size: 135%!important;
        line-height: 1.15;
    }
    .heroSubheader > span {
        font-size: 78%!important;
        letter-spacing: -.1px;
    }

    .container-main-text {
        justify-content: center;
    }

    .company-logo {
        margin-left: 1rem!important;
    }

    .img-custom-soft{
        width: auto!important;
        margin: 0;
    }

    .container-main-action {
        width: 90%!important;
    }

    .container-main-action > h6 {
        padding: 1rem 0;
    }

    #container-main-action-bg {
        height: 50em!important;
    }
}

@media only screen and (max-width: 414px) {

    .container-custom{
        width: 99%;
        margin: auto!important;
        text-align: center;
    }

    .drawer-container-icon{
        flex-basis: 65%!important;
    }

    img.icon-hide {
        display: none!important;
    }

    .heroSubheader {
        font-size: 115%!important;
        line-height: 1.15;
    }
    .heroSubheader > span {
        font-size: 78%!important;
        letter-spacing: -.1px;
    }

    .container-custom > h4 {
        font-size: 1.57rem;
    }

    .container-main-subtitle { 
        justify-content: center;
    }

    #container-subheader-btn {
        flex-direction: column!important;
        align-items: center!important;
        justify-content: center!important;
    }

    #container-subheader-btn > div > div {
        margin-bottom: 5rem!important;
    }

    .container-main-action {
        width: 95%!important;
    }

    .container-main-action > h6 {
        padding: 1rem 0;
    }

    .container-custom > h4 + h6, .container-main-subtitle {
        padding-top: .2rem!important;
        font-size: 1.19rem!important;
        font-weight: 400!important;
        color: #060d3a!important;
    }

    /* .container-main-action-subtitle{
        line-height: 1;
        font-weight: 500;
    } */

    .container-main-text {
        justify-content: center;
    }

    .container-main-text > div {
        padding: 1rem 24px;
    }

    #container-main-action-bg {
        height: 50em!important;
    }
    .container-main-text > div > h6:last-child{
        padding: 0 .5rem;
    }

    .container-custom > h4 + h6 > span, .container-main-subtitle > span{
        color: #020733!important;
        letter-spacing: .2px!important;
        font-variant: small-caps!important;
    }
    
    .container-custom > h6{
        font-size: 1.3rem;
    }

    img.img-custom-soft{
        width: auto!important;
        margin: 0;
    }

    #logo.company-logo {
        margin-left: 4.5rem!important;
    }
}


.MuiPaper-elevation0 {
    background-color: #05081a!important;
}

.MuiPaper-elevation4{
    background-color: hsl(231deg 68% 6% / 90%)!important;
}

.MuiTabs-indicator {
    background-color: #F57A46!important;
}
.MuiButtonBase-root:hover > span {
    color: #F57A46!important;
    text-decoration: none!important;
}

a.MuiButtonBase-root:hover {
    color: #F57A46!important;
    text-decoration: none!important;
}

a.MuiButtonBase-root:has(learnButton):hover {
    color: #05081a!important;
    text-decoration: none!important;
}

.container-main-hero {
    background: url(../assets/contact-11.jpg) no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 100vh;
    background-position: revert;
}

.row-container{
    display: flex;
    flex-flow: row wrap;
    place-content: space-between;
    place-items: flex-start;
    justify-self: center;
}

.container-text-founder > div > p{
    color: #fff!important;
    font-size: 1.2em;
}

.copyright-section-text{
    font-size: 15px;
    color: #b9bec4;
    margin-bottom: 0;
    font-weight: 500;
}

.copyright-section-text a {
    color: #0181F5;
}

footer {
    position: relative;
}

.copyright-section {
    position: absolute;
    bottom: 20px;
    right: 50px;
}


a[href="/contact"]:hover > span, a[href="/about"]:hover > span,
.contact-btn:hover, .about-btn:hover {
    color: #fff!important;
}
.contact-btn:hover > #contact-btn, 
.about-btn:hover > #about-btn{
    color: #fff!important;
}