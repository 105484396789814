header > div{
    height: 80px;
}

.makeStyles-toolbarMargin-1{
    margin-bottom: 0!important;
}


.section-header{
    height: 95vh;
    display: flex;
    flex-flow: row wrap;
    place-content: center;
    margin-left: 0!important;
}

header ~ div{
 margin-top: 0;
}

.heroSubheader {
    font-size: 5.3rem;
    height: auto;
    justify-self: center;
    line-height: 1;
    margin-bottom: 1rem;
}

h2.MuiTypography-h2 > div > a {
    color: #fff;
    padding: .66rem;
    font-weight: 400;
    width: 155px;
    height: auto;
}

h2.MuiTypography-h2 > p > span{
    font-size: 4.7rem;
    color: #F57A46;
}

img {
    place-content: center!important;
}


@media only screen and (max-width: 640px) {

    img.icon-hide {
        display: none!important;
    }

}

a[href="/services"]:hover > span {
    color: rgba(255, 255, 255, 0.877)!important;
}   


a[href="/ui-ux"]:hover > span,
a[href="/customsoftware"]:hover > span,
a[href="/websites"]:hover > span{
    color: #05081a!important;
}